.d-card {
	padding: 16px;
	border-radius: 14px;
	margin: 0 0 30px;
	position: relative;
	background-color: #ffffff;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	&:not(.feed-wrap) {
		border: 1px solid #dddddd;
	}

	&.height-equal {
		height: calc(100% - 30px);
	}

	&.height-360 {
		height: 360px;
	}
	&.auto {
		height: auto;
	}

	.d-card-head {
		display: flex;
		justify-content: space-between;
		margin: 0 0 14px;

		.d-title {
			font-size: 14px;
			line-height: 18px;
			text-transform: uppercase;
			font-weight: 700;
			margin: 0;
		}

		.d-title-no-case {
			font-size: 14px;
			line-height: 18px;
			font-weight: 700;
			margin: 0;
		}

		.dashboard-title {
			font-size: 14px;
			line-height: 18px;
			text-transform: uppercase;
			font-weight: 700;
			margin: 0;
		}

		.link {
			font-size: 12px;
			line-height: 16px;
			font-weight: 600;
			padding: 0;
			height: auto;
		}
	}

	.d-card-body {
		flex: 1 1 auto;

		&.vh-center {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.data-card {
		width: 100%;
		padding: 22px 12px;
		box-shadow: 0px 0px 16px #0000000d;
		border-radius: 10px;
		text-align: center;

		&.bg-ffffff {
			background-color: #fff;
			border: 1px solid #dddddd;
		}
		&.bg-6CB761 {
			background-color: var(--fern);
		}
		&.bg-018DD3 {
			background-color: var(--theme-primary);
		}
		&.bg-1d8627 {
			background-color: var(--forest-green);
		}
		&.bg-e75da7 {
			background-color: var(--deep-blush);
		}
		&.bg-14d6d3 {
			background-color: var(--bright-turquoise);
		}
		&.bg-6294FF {
			background-color: #6294ff;
		}
		&.bg-429EBD {
			background-color: #429ebd;
		}
		&.bg-3D5A80 {
			background-color: #3d5a80;
		}
		&.bg-f7a34d {
			background-color: var(--tan-hide);
		}
		&.dark-text {
			h5 {
				color: var(--theme-primary);
			}
			p {
				color: #000000;
			}
		}
		&.light-text {
			h5,
			p {
				color: #fff;
			}
		}
		h5 {
			font-size: 18px;
			line-height: 24px;
			font-weight: 700;
			margin: 0 0 4px;
		}
		p {
			font-size: 12px;
			line-height: 16px;
			margin: 0;
		}
	}
	.justify-data-box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.data-card {
			max-width: 48%;
		}
	}
	.label-heading {
		font-size: 12px;
		line-height: 16px;
		font-weight: 600;
	}
	.list-data-wrap {
		margin: 0;
		padding: 0;
		list-style: none;

		> li {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 12px;
			line-height: 26px;

			&.centered {
				justify-content: center;
				gap: 0.5rem;
			}

			label {
				margin: 0;
				display: flex;
				align-items: center;

				button {
					border: none;
					width: 16px;
					height: 16px;
					border-radius: 4px;
					padding-bottom: 0;
					margin-right: 8px;

					&.without-refferals {
						background: #f0f0f0;
					}
					&.with-refferals {
						background: var(--royal-blue);
					}
				}
			}
			span {
				font-weight: 700;
			}
		}

		&.alternate {
			margin: 0 -16px;
			> li {
				padding: 0 16px;

				&:nth-child(even) {
					background-color: #21365d08;
				}
			}
		}
	}

	.donut-chart-labels {
		display: grid;
		grid-template-columns: repeat(2, max-content);
		gap: 0.5rem;
		margin: 0 auto;
		padding: 0;

		label {
			margin: 0;
			text-align: end;
		}

		span {
			font-weight: 700;
		}
	}

	.view-more {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		text-align: right;
		background-color: #fff;
		padding: 4px 16px;

		.more-less {
			font-size: 12px;
			line-height: 16px;
			font-weight: 600;
			padding: 0;
			height: auto;
		}
	}
	.tabs {
		display: flex;
		border-bottom: 2px solid #e2e2e2;
		margin: 0 0 12px;

		span {
			flex: 1;
			font-size: 14px;
			line-height: 20px;
			font-weight: 700;
			padding: 8px;
			border-bottom: 4px solid transparent;
			text-transform: uppercase;
			text-align: center;
			cursor: pointer;

			&.active {
				border-color: var(--cello);
			}
		}
	}
	.leaderboard-select {
		display: flex;
		align-items: center;
		margin-right: -12px;

		label {
			font-size: 12px;
			line-height: 18px;
			color: var(--theme-primary);
			font-weight: 600;
			margin: 0;
		}
		.ant-select-selector {
			border: none;
			color: var(--theme-primary);
			height: 18px;
			padding: 0;
			margin-right: 6px;

			.ant-select-selection-item {
				font-size: 12px;
				line-height: 18px;
				font-weight: 600;
				color: var(--theme-primary);
				margin-left: 4px;
			}

			&:focus,
			&:active {
				box-shadow: none;
				-webkit-box-shadow: none;
			}
		}
	}

	.donutchart-innertext {
		display: none;
	}

	.no-content {
		margin: auto;
	}
	&.dashboard-pagination {
		.ant-list-pagination {
			top: -42px;
			right: 0;
			position: absolute;

			.ant-pagination-item {
				display: none;
			}
		}
	}
	.d-number {
		font-size: 30px;
		line-height: 30px;
		font-weight: 700;
		color: #000000;
		margin: 0;
	}
	.d-info {
		font-size: 16px;
		line-height: 20px;
		font-weight: 600;
		margin: 0;
	}

	.announcement-title-wrap {
		display: flex;
		justify-content: space-between;
		margin: 0 0 12px;

		.announcement-title {
			font-size: 14px;
			line-height: 18px;
			font-weight: 700;
			margin: 0;
		}
	}

	.job-card-wrap,
	.ant-spin-nested-loading,
	.ant-spin-container,
	.ant-list-item {
		height: 100%;
	}
	.job-card-wrap {
		.ant-col,
		.ant-list-item {
			margin: 0 !important;
		}
	}
}

.send-modal-btn {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 8px;

	.ant-input {
		border-radius: 24px;
		border: 1px solid;
		border-color: var(--primary-btn-color, var(--theme-primary));
		height: 34px;
	}

	.ant-btn:focus {
		border-color: var(--primary-btn-color, var(--theme-primary));
	}

	.ant-input::placeholder {
		color: #2980b9;
		font-weight: 700;
	}

	.ant-select-selection__placeholder {
		color: #2980b9;
		font-size: 14px;
		font-weight: 700;
	}

	.ant-btn {
		border-radius: 24px;
		border: 1px solid;
		border-color: var(--primary-btn-color, var(--theme-primary));
		color: var(--primary-btn-color, var(--theme-primary));
		font-size: 14px;
		font-weight: 700;
		height: 34px;
	}
	.ant-btn:disabled {
		&:not(.add-reward-btn) {
			background-color: #e8e8e8;
			border-color: #e8e8e8;
			color: rgba(0, 0, 0, 0.2);
			filter: grayscale(100%);

			svg {
				color: rgba(0, 0, 0, 0.2);

			}

			img {
				opacity: 0.5;
			}
		}
	}
	.add-reward-btn {
		padding: 2px 10px;
		background-color: #fff;
		svg {
			margin-right: 4px;
			transition:
				transform 0.3s ease,
				color 0.3s ease;
			color: var(--primary-btn-color, var(--theme-primary));
		}
	}
	.reward-unselected {
		&.ant-btn:hover {
			color: var(--primary-btn-color, var(--theme-primary));
			border: 1px solid;
			background-color: #fff;
			border-color: var(--primary-btn-highlight-color, var(--theme-primary-highlight));
		}
	}
	.reward-selected:hover {
			color: var(--primary-btn-highlight-color, var(--theme-primary-highlight));
			border: 1px solid;
			border-color: var(--primary-btn-highlight-color, var(--theme-primary-highlight));

			svg {
				transition: transform 0.3s ease,
				color 0.3s ease;
				color: var(--primary-btn-highlight-color, var(--theme-primary-highlight));
			}
	}
	.send-modal-container {
		display: flex;
		gap: 5px;
		flex-wrap: wrap;
		.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
			min-width: 150px;
			border-radius: 24px;
			border: 1px solid var(--primary-btn-color, var(--theme-primary));
			color: var(--primary-btn-color, var(--theme-primary));
			font-size: 14px;
			font-weight: 700;
			.ant-select-selection-placeholder {
				color: var(--primary-btn-color, var(--theme-primary));
			}
		}
	}
}

.send-primary-btn {
	display: flex;
	justify-content: flex-end;

	.ant-btn {
		background-color: var(--primary-btn-color, var(--theme-primary));
		border-color: var(--primary-btn-color, var(--theme-primary));
		color: white;
		&:disabled {
			color: rgba(0, 0, 0, 0.25) !important;
			background-color: #e8e8e8;
			border-color: #e8e8e8;
			&:hover {
				color: rgba(0, 0, 0, 0.25) !important;
				background-color: #e8e8e8 !important;
				border-color: #e8e8e8 !important;
			}
		}
		&:hover {
			background-color: var(
				--primary-btn-color,
				var(--theme-primary)
			) !important;
			border-color: var(--primary-btn-color, var(--theme-primary)) !important;
		}
	}
}

.e-card-wrap {
	padding: 30px 10px;
	border-radius: 20px;
	text-align: center;
	height: calc(100% - 30px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&.bg-blue {
		background-color: #4287f5;
	}
	&.bg-green {
		background-color: #00ebd0;
	}

	&.bg-14D6D3 {
		background-color: var(--bright-turquoise);
	}
	&.bg-E75DA7 {
		background-color: var(--deep-blush);
	}

	.e-card-title {
		color: #fff;
		font-size: 60px;
		line-height: 60px;
		margin: 0 0 10px;
		font-weight: 800;
	}
	.e-card-subtitle {
		color: #fff;
		font-size: 22px;
		line-height: 22px;
		margin: 0 0 16px;
		font-weight: 600;
	}

	&.job-alert-wrap {
		.e-card-title {
			color: #000;
		}
		.job-alert-card-subtitle {
			margin: 0 0 22px;

			h5 {
				color: #fff;
				font-size: 18px;
				line-height: 22px;
				margin: 0;
			}
			p {
				color: #fff;
				font-size: 12px;
				line-height: 14px;
				margin: 0;
			}
		}
		a {
			color: #fff;
			font-size: 12px;
			line-height: 14px;
			margin: 8px 0 0;
		}
	}

	.c-btn-white {
		font-size: 16px;
		line-height: 18px;
		font-weight: 700;
		padding: 10px 42px;
		color: #3076bb;
		background-color: #ffffff;
		border-radius: 50px;
		border: transparent;
	}
}

.send-reward-modal {
	.select-recipient-text {
		color: #95979a;
		text-align: center;
		font-size: 16px;
		font-weight: normal;
		padding-bottom: 20px;
	}

	.custom-label {
		color: #98979a;
		font-size: 14px;
		font-weight: 600;
	}

	.enter-bonus-amount {
		width: 100%;
		border-radius: 8px;
		border: 1px solid #d6d6d6;
	}

	.gray-border-btn {
		width: 150px;
		border-radius: 24px;
		border: 1px solid #98979a;
		color: #98979a;
		text-align: center;
		font-size: 14px;
		font-weight: 700;
	}

	.blue-bg-btn {
		width: 33%;
		border-radius: 24px;
		background-color: var(--primary-btn-color, var(--theme-primary));
		border-color: var(--primary-btn-color, var(--theme-primary));
		color: #fff;
		text-align: center;
		font-size: 14px;
		font-weight: 700;
		&:hover {
			border-color: var(--primary-btn-highlight-color, var(--theme-primary-highlight));
			background-color: var(--primary-btn-highlight-color, var(--theme-primary-highlight));
		}
		&:disabled {
			color: rgba(0, 0, 0, 0.25) !important;
			background-color: #e8e8e8;
			border-color: #e8e8e8;
			&:hover {
				color: rgba(0, 0, 0, 0.25) !important;
				background-color: #e8e8e8 !important;
				border-color: #e8e8e8 !important;
			}
		}
	}

	.send-reward-slider {
		display: flex;
		justify-content: center;
		border-radius: 8px;
		background-color: #eeeeee;
	}

	.slider-container {
		padding: 10px;
	}

	.slider-container-hover {
		border: 1px solid #fff;
		transition: box-shadow 0.3s;
		&:hover {
			border-radius: 10px;
			border: 1px solid #ccc;
			box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
		}
	}

	.slick-prev:before,
	.slick-next:before {
		color: #6f6e70;
	}

	.badge-slider {
		width: 100%;
	}

	.main-slider-container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 28px;
	}

	.slick-initialized .slick-slide {
		display: flex;
		justify-content: center;
	}

	.slider-img {
		transition: 0.2s;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		.active-tick {
			background-color: #0686d0;
			color: white;
			font-weight: bold;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			position: absolute;
			right: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.badges-img {
		height: 100px;
		width: 100px !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.reward-modal {
	.opational-label {
		color: #98979a;
		padding-top: 2px;
	}

	.ant-tabs-tab {
		font-weight: 600;
		font-size: 16px;
		color: #6f6e70;
	}

	.ant-tabs-nav .ant-tabs-tab-active {
		color: #018dd3;
	}
}

.modal-footer-btn {
	.pb-button {
		width: 250px !important;
	}
}

.svg-size svg {
	width: 151px;
	height: 135px;
}

.ant-empty-normal {
	width: 100%;
}

.rewards-points {
	.reward-point-title {
		font-weight: 700;
		color: #6f6e70;
		font-size: 18px;
		padding-bottom: 14px;
	}

	.reward-point-boxes {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		column-gap: 10px;
		row-gap: 20px;

		@media (min-width: 320px) and (max-width: 560px) {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}

		&.home-reward-boxes {
			grid-template-columns: repeat(1, minmax(0, 1fr));

			@media (min-width: 320px) and (max-width: 480px) {
				grid-template-columns: repeat(1, minmax(0, 1fr));
			}

			.custom-cards {
				width: auto !important;
			}
		}

		.reward-point-img img {
			@media screen and (min-width: 1199px) and (max-width: 1400px) {
				max-width: 40px;
			}
		}

		.send-points {
			background-color: #14d6d3;
			border-radius: 8px;
			height: 90px !important;
			display: flex;
			align-items: center;
			justify-content: center;
			&.custom-cards {
				padding: 12px;
			}
			.points-detail {
				display: flex;
				align-items: center;
				color: white;
				justify-content: center;
				column-gap: 10px;
				row-gap: 15px;

				&.points-bonus {
					flex-direction: column;
				}
				.reward-point-img {
					img {
						width: 55px;
					}
				}

				.about-points {
					.point-amount {
						font-weight: 700;
						font-size: 24px;

						@media screen and (min-width: 1199px) and (max-width: 1400px) {
							font-size: 20px;
						}
					}

					.point-info {
						font-size: 12px;
						font-weight: 600;

						&.info-bold {
							margin-bottom: 10px;
						}
					}
				}
			}
		}

		.reward-bonus {
			background-color: #6cb761;
			height: 90px !important;
			border-radius: 8px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			&.custom-cards {
				padding: 12px;
			}
			.points-detail {
				display: flex;
				align-items: center;
				justify-content: center;
				color: white;
				row-gap: 15px;
				column-gap: 10px;

				.reward-point-img {
					display: flex;
					align-items: center;
					font-weight: 700;
					font-size: 24px;
				}

				.about-points {
					.point-amount {
						font-weight: 700;
						font-size: 24px;

						@media screen and (min-width: 1199px) and (max-width: 1400px) {
							font-size: 20px;
						}
					}

					.point-info {
						font-weight: 600;
						font-size: 12px;
					}
				}
			}
		}
	}

	.total-earned {
		color: #b1b1b1;
		font-size: 12px;
		text-align: center;
		padding: 8px 0px;
	}
}

.recent-activity {
	height: 320px;

	.recent-activity-title {
		font-weight: 700;
		color: #6f6e70;
		font-size: 14px;
		padding-bottom: 6px;
	}

	.activity-detail {
		height: 270px;
		overflow-y: auto;

		.about-activity-detail {
			padding: 0 8px;
			display: flex;
			margin-bottom: 5px;
			align-items: flex-start;

			.activity-img {
				width: 26px;
				height: 26px;
				margin-right: 16px;
			}

			.activity-info {
				color: #6f6e70;
				font-weight: 600;
				font-size: 12px;
			}

			.total-earned {
				font-size: 11px;
				color: var(--oslo-gray);
			}
		}
	}

	.show-more-btn {
		color: #018dd3;
		font-weight: 600;
		font-size: 14px;
		display: flex;
		justify-content: center;

		button {
			background-color: transparent;
			border: none;
			outline: none;
		}
	}
}

.Feed {
	.feed-title {
		font-weight: 700;
		color: #6f6e70;
		font-size: 14px;
	}

	.feed-card {
		border: 1px solid #dddddd;
		border-radius: 10px;
		padding: 10px;

		.about-feed {
			display: flex;

			.feed-author {
				display: flex;
				align-items: center;

				.feed-img {
					margin-right: 4px;
				}

				.feed-name {
					color: #222222;
					font-weight: 700;
					font-size: 14px;
				}
			}

			//.feed-reward-logo {
			//	width: 22px;
			//	height: 27px;
			//	margin: 0 10px;
			//}

			.feed-points {
				background: rgba(215, 248, 194, 1);
				border-radius: 24px;
				font-weight: 600;
				font-size: 11px;
				color: rgba(29, 134, 39, 1);
				padding: 5px;
				display: block;
				margin-left: 3px;
			}
		}

		.feed-reply {
			font-size: 16px;
			line-height: 21px;
			color: #000;
		}

		.send-by {
			.send-by-text {
				color: #b1b1b1;
				font-size: 10px;
				padding-right: 8px;
			}

			.send-by-name {
				color: #666666;
				font-size: 12px;
				font-weight: 600;
			}
		}

		.feed-image {
			width: 240px;
			height: 240px;
			border: 2px solid gainsboro;
			margin: 0 auto;
		}
	}
}

.card-detail {
	padding: 5px 14px;
	margin-bottom: 10px;
	font-size: 12px;
}

.reward-card {
	.reward-card-title {
		font-weight: 700;
		color: #6f6e70;
		font-size: 14px;
	}

	.reward-card-view-all {
		color: #018dd3;
		font-weight: 600;
		font-size: 14;
	}

	.reward-card-points {
		font-weight: 600;
		font-size: 12px;
		color: #b1b1b1;
	}

	.reward-card-bonus {
		margin-top: -4px;
		font-weight: 600;
		font-size: 12px;
		color: #1d8627;
	}

	.reward-card-view-all {
		cursor: pointer;
	}
}

.nominated-award-card {
	background-color: white;
	border: 1px solid #dddddd;
	border-radius: 20px;
	padding: 14px;

	.nominated-award-title {
		font-weight: 700;
		color: #6f6e70;
		font-size: 18px;
	}

	.about-votes {
		color: #6f6e70;
		font-size: 16px;
		font-weight: 700;
	}

	.vote-now-btn {
		border: 1px solid #018dd3;
		width: 232px;
		height: 42px;
		border-radius: 28px;
		color: #018dd3;
		font-weight: 600;
		font-size: 18px;
		text-align: center;
		background-color: white;
	}
}

.reward-leaderboard-card {
	background-color: white;
	border: 1px solid #dddddd;
	border-radius: 20px;
	padding: 14px;
	margin-top: 12px;

	.reward-leaderboard-card-title {
		font-weight: 700;
		color: #6f6e70;
		font-size: 14px;
	}

	.reward-leaderboard-card-view-all {
		color: #018dd3;
		font-weight: 700;
		font-size: 14px;
	}

	.reward-leaderboard-list-name {
		color: #6f6e70;
		font-weight: 400;
		font-size: 14px;
	}

	.reward-leaderboard-list-points {
		font-weight: 700;
		color: #6f6e70;
		font-size: 14px;
	}

	.reward-leaderboard-list-points-blue {
		font-weight: 700;
		color: #018dd3;
		font-size: 16px;
	}
}

.dashboard-grid {
	display: grid;
	gap: 30px;

	.card-scroll {
		overflow-y: hidden;
		position: sticky;
		top: 85px;
		.Feed {
			overflow-y: auto;
		}
		.d-card {
			margin-bottom: 0px;
		}
	}
	.gif-emogi {
		cursor: pointer;
	}

	.gif-emogi-modal {
		width: 100%;
		overflow: auto;
		.GifPickerReact.gpr-main {
			width: 100% !important;
			height: 300px !important;
		}

		.epr_6ocl7q {
			width: 100% !important;
			height: 300px !important;
		}
	}

	.selected-gif {
		width: 200px;
		background-color: black;
		height: 200px;
		position: relative;
		margin: 0 auto;

		img {
			width: 100%;
			height: 100%;
		}

		.gif-close-btn {
			position: absolute;
			right: 4px;
			top: 4px;
			cursor: pointer;
			background-color: white;
			padding: 4px;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.text-area-gif {
		border: 1px solid #dddddd;
		border-radius: 14px;
		padding: 4px;

		.ant-input {
			border: none;
			-webkit-box-shadow: none;
		}
	}
}

@media (min-width: 1280px) {
	.dashboard-grid {
		display: grid;
		grid-template-columns: 385px 1fr 275px;
		gap: 12px;
		max-width: 1630px;
		margin: auto;

		.card-scroll {
			position: static;
			max-height: max-content;
			&.feed-container {
				border-radius: 14px;
				border: 1px solid #dddddd;
			}
		}

		.reward-badges-size {
			svg {
				width: 80px;
				height: 80px;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 1279px) {
	.dashboard-grid {
		display: grid;
		grid-template-columns: 1fr;
		gap: 8px;

		.Feed {
			height: auto;
		}

		.card-scroll {
			max-height: max-content;
			position: static;
		}

		.rewards-points {
			.reward-point-boxes {
				padding: 0;
			}

			.send-points {
				width: 130px;
				height: 140px;

				.points-detail {
					flex-direction: column;
					align-items: center;
					justify-content: center;
					text-align: center;

					.about-points {
						.point-amount {
							font-weight: 700;
							font-size: 18px;
						}

						.point-info {
							font-weight: 600;
							font-size: 14px;
						}
					}
				}
			}

			.reward-bonus {
				.points-detail {
					flex-direction: column;
					align-items: center;
					justify-content: center;
					text-align: center;
					.about-points {
						.point-amount {
							font-weight: 700;
							font-size: 18px;
						}

						.point-info {
							font-weight: 600;
							font-size: 14px;
						}
					}
				}
			}
		}

		.reward-card {
			img {
				width: 88px;
			}
		}
	}
}

@media (min-width: 320px) and (max-width: 767px) {
	.dashboard-grid {
		display: grid;
		grid-template-columns: 1fr;
		gap: 8px;

		.Feed {
			height: auto;
		}

		.card-scroll {
			max-height: max-content;
			position: static;
		}

		.send-modal-btn {
			display: grid;
			grid-template-columns: 1fr;
			gap: 8px;
		}

		.rewards-points {
			.reward-point-boxes {
				padding: 0;
			}

			.send-points {
				.points-detail {
					flex-direction: column;
					align-items: center;
					justify-content: center;
					text-align: center;

					.about-points {
						.point-amount {
							font-weight: 700;
							font-size: 18px;
						}

						.point-info {
							font-weight: 600;
							font-size: 14px;
						}
					}
				}
			}

			.reward-bonus {
				.points-detail {
					flex-direction: column;
					align-items: center;
					justify-content: center;
					text-align: center;

					.about-points {
						.point-amount {
							font-weight: 700;
							font-size: 18px;
						}

						.point-info {
							font-weight: 600;
							font-size: 14px;
						}
					}
				}
			}

			.total-earned {
				color: #b1b1b1;
				font-size: 12px;
				text-align: start;
				padding: 8px 0px;
			}
		}

		.reward-card {
			img {
				width: 88px;
			}
		}
	}

	.dashboard-grid {
		.send-reward-modal {
			.send-modal-btn {
				.ant-select {
					min-width: 100%;
				}
			}
		}
	}

	.main-slider-container {
		.slick-track {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.slider-badges-svg {
			height: 120px;

			svg {
				width: 64px;
				height: 64px;
			}
		}

		.img-label {
			width: 64px;
		}
	}

	.reward-card {
		.reward-card-points {
			font-size: 14px;
		}

		.reward-card-bonus {
			font-size: 14px;
		}
	}

	.reward-leaderboard-card {
		.reward-leaderboard-card-title {
			font-size: 16px;
		}
	}
}

.confirmation-modal .modal-detail {
	color: #95979a;
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	padding-bottom: 20px;
}

.confirmation-modal .modal-detail {
	color: #95979a;
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	padding-bottom: 20px;
}

.confirmation-modal .blue-bg-btn {
	width: 150px;
	border-radius: 24px;
	background: #0686d0;
	color: #fff;
	text-align: center;
	font-size: 14px;
	font-weight: 700;
}

// ANIMATIONS
@-webkit-keyframes rubberBand {
	0% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	30% {
		-webkit-transform: scale3d(1.25, 0.75, 1);
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		-webkit-transform: scale3d(0.75, 1.25, 1);
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		-webkit-transform: scale3d(1.15, 0.85, 1);
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		-webkit-transform: scale3d(0.95, 1.05, 1);
		transform: scale3d(0.95, 1.05, 1);
	}
	75% {
		-webkit-transform: scale3d(1.05, 0.95, 1);
		transform: scale3d(1.05, 0.95, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@keyframes rubberBand {
	0% {
		-webkit-transform: scale3d(1, 1, 1);
		-ms-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	30% {
		-webkit-transform: scale3d(1.25, 0.75, 1);
		-ms-transform: scale3d(1.25, 0.75, 1);
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		-webkit-transform: scale3d(0.75, 1.25, 1);
		-ms-transform: scale3d(0.75, 1.25, 1);
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		-webkit-transform: scale3d(1.15, 0.85, 1);
		-ms-transform: scale3d(1.15, 0.85, 1);
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		-webkit-transform: scale3d(0.95, 1.05, 1);
		-ms-transform: scale3d(0.95, 1.05, 1);
		transform: scale3d(0.95, 1.05, 1);
	}
	75% {
		-webkit-transform: scale3d(1.05, 0.95, 1);
		-ms-transform: scale3d(1.05, 0.95, 1);
		transform: scale3d(1.05, 0.95, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		-ms-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
.rubberBand {
	-webkit-animation-name: rubberBand;
	animation-name: rubberBand;
}
@keyframes subtleFloat {
	0%,
	100% {
		transform: scale(1.04) translateY(0);
	}
	25%,
	75% {
		transform: scale(1.04) translateY(-2px);
	}
	50% {
		transform: scale(1.04) translateY(0);
	}
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.fadeInDown {
	animation-name: fadeInDown;
}

.fadeIn {
	animation-name: fadeIn;
}

.animate__fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	animation-timing-function: cubic-bezier(0.4, -0.2, 0.05, 1);
}
.animate__fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

.animate__animated {
	-webkit-animation-duration: 0.8s;
	animation-duration: 0.8s;
	-webkit-animation-duration: 0.8s;
	animation-duration: 0.8s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.animate__animated_slow {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

// Admin Analytics UI
.statistics-title {
	margin-left: 15px;
}

.statistics-title,
.analytics-title {
	width: 100%;
	h3 {
		width: 100%;
		color: var(--black);
		font-size: 20px;
		line-height: 20px;
		font-weight: 700;
		margin: 0px 0px 8px 8px;
	}
}

.analytics-title {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 30px;
	margin-bottom: 8px;
	h3 {
		margin-right: 4px;
		margin-bottom: 0;
		display: inline-flex;
		width: auto;
	}
}

.analytics-cards-container {
	margin-bottom: 16px;
	display: grid;
	grid-auto-rows: minmax(220px, auto);
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	width: 100%;
	gap: 16px;
}

.analytics-card-wrapper {
	height: 100%;
	border-radius: 14px;
	border: 1px solid #dddddd;
	transition:
		transform 0.3s ease,
		box-shadow 0.3s ease;
	will-change: transform, box-shadow;
}

.analytics-card-wrapper:hover {
	border: 1px solid #bab9b9;
	border-radius: 14px;
	transform: scale(1.04);
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.13);
	z-index: 3;
}

.analytics-card-wrapper:has(> .analytics-card > .analytics-card-content):hover {
	animation: subtleFloat 6s infinite ease-in;
}

.analytics-card-wrapper {
	transition:
		transform 0.3s ease,
		box-shadow 0.3s ease,
		border 0.3s ease;
}

.analytics-card {
	height: 100%;
	padding: 16px;
	border-radius: 14px;
	position: relative;
	background-color: #ffffff;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.analytics-card-content {
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 0px;
	height: 100%;
}

.analytics-card-content-left {
	display: flex;
	align-items: center;
}

.analytics-card-graphic {
	width: 100%;
	height: auto;
	min-width: 100px;
	max-width: 160px;
	&.grayscale {
		filter: grayscale(100%);
	}
}
.analytics-card-content-right {
	min-width: 130px;
	max-width: 150px;
	display: grid;
	grid-template-rows: 1fr auto;
	height: 100%;
	user-select: none;
}

.analytics-card-content-right-top {
	align-self: center;
}

.analytics-card-content-right-bottom {
	align-self: end;
}

.analytics-card-stat-label {
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	color: var(--dove-gray);
	user-select: none;
	&.analytics-card-inactive {
		color: var(--oslo-gray);
	}
}

.analytics-card-stat-data {
	font-size: 38px;
	font-weight: 800;
	color: var(--black);
	text-align: center;
	user-select: none;
	&.analytics-card-inactive {
		color: var(--oslo-gray);
		text-align: center;
		font-size: 14px;
		margin-bottom: 16px;
	}
}

.analytics-card-stat-days {
	font-size: 24px;
	text-align: center;
	color: var(--black);
	font-weight: 900;
	user-select: none;
	&.analytics-card-inactive {
		color: var(--oslo-gray);
	}
}

.analytics-card-edit {
	display: grid;
	place-items: center;
	height: 100%;
}

.ant-btn-icon-only {
	border: none;
}

.ant-select-dropdown .badge-count {
	font-size: 12px;
	font-style: italic;
	color: #95979a;
	font-weight: 500;
}

.ant-tabs {
	.ant-tabs-nav {
		.ant-tabs-nav-list {
			.ant-tabs-tab {
				.ant-tabs-tab-btn {
					color: #b7b7b7;
				}
				&.ant-tabs-tab-active {
					.ant-tabs-tab-btn {
						color: #0686d0;
					}
				}
			}
		}
	}
}
